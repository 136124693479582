<template>
  <div class="appointRole">
    <div class="sub">
      <overflowYHidden>
        <div class="record">
          <div class="title">历史记录</div>
          <div class="roleList">
            <van-swipe-cell
              v-for="item in roleList"
              :key="item.key"
              :before-close="deleteRole"
              @open="swipeClick(item)"
            >
              <div
                class="item"
                @click="selectUser(item)"
                :class="{ activeItem: item.id == roleId }"
              >
                <img v-if="item.photo" :src="$replacePhoto(item.photo)" alt="" />
                <img v-else :src="yonghu" alt="" />
                <div class="content">
                  <div>
                    <span class="name"> {{ item.name }}</span>
                    <span class="company"
                      >{{ getRepairUserInfo(item).company }}维修员</span
                    >
                  </div>
                  <div class="duty">上门维修服务</div>
                </div>
                <div class="icon">
                  <van-icon name="arrow" />
                </div>
                <span class="check" v-if="item.id == roleId">
                  <van-icon name="success" />
                </span>
              </div>
              <template #right>
                <van-button
                  square
                  text="删除"
                  type="danger"
                  class="delete-button"
                />
              </template>
            </van-swipe-cell>

            <div class="addRole" @click="toAddRole">
              <van-icon name="add-o" />
              <div>添加维修成员</div>
            </div>
          </div>
        </div>
      </overflowYHidden>
    </div>
    <div
      class="footer"
      :class="{ activeFooter: roleId != '' }"
      @click="showDialog"
    >
      指派工程师
    </div>
    <van-dialog
      v-model="assignVisible"
      show-cancel-button
      :beforeClose="reportAssign">
      <div class="dialogContent">
        <div class="main">
          <div class="title">维修时间</div>
          <div class="userTime">
            <div class="button timeButton" @click="selectRepairTime">
              {{ formatDate(repairTime) }}
            </div>
          </div>
        </div>
        <div class="main">
          <div class="title">备注:</div>
          <div class="textarea">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder=""
              v-model="remark"
            >
            </el-input>
          </div>
        </div>
      </div>
    </van-dialog>
    <popupDatetimePicker
      ref="popupDatetimePicker"
      :formatter="formatter"
      v-model="repairTime"
      :type="'datetime'"
    ></popupDatetimePicker>
  </div>
</template>

<script>
import overflowYHidden from "@/components/overflowYHidden";
import popupDatetimePicker from "@/components/popupDatetimePicker";
export default {
  components: { overflowYHidden, popupDatetimePicker },
  data() {
    return {
      assignVisible: false,
      repairTime: "",
      remark: "",
      page: 1,
      pageSize: 30,
      roleId: "",
      roleList: [],
      userInfo: {},
      yonghu: require("@/icons/imgs/yonghu.png"),
      id: "",
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.id = this.$route.query.id;
    this.userListForRepair();
  },
  methods: {
    showDialog() {
      if (!this.roleId) {
        this.$toast.fail("请选择工程师");
        return;
      }
      this.assignVisible = true;
    },
    selectRepairTime() {
      this.repairTime = ''
      this.$refs.popupDatetimePicker.show();
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      } else if (type === "hour") {
        return `${val}时`;
      } else if (type === "minute") {
        return `${val}分`;
      }
    },
    userListForRepair() {
      this.$api.userInfo
        .userListForRepair({
          enable: 1,
          deleted: 0,
          tenantId: this.userInfo.tenantId,
          page: this.page,
          pageSize: this.pageSize,
          roleName: "ROLE_REPORT",
        })
        .then((res) => {
          this.roleList = res.data;
        })
        .catch((res) => {});
    },
    getRoleName(item) {
      if (item.roleList.length > 0) {
        return item.roleList[0].name;
      } else {
        return "";
      }
    },
    getRepairUserInfo(item) {
      return JSON.parse(item.repairUserInfo) || {};
    },
    deleteRole({ position, instance }) {
      switch (position) {
        case "left":
        case "cell":
        case "outside":
          instance.close();
          break;
        case "right":
          this.$dialog.confirm({
            message: `确认删除该工程师？`,
            beforeClose: (action, done) => {
              if (action === "confirm") {
                this.$api.userInfo
                  .deleteUser({
                    ids: this.roleId,
                    deleted: 1,
                  })
                  .then((res) => {
                    this.$toast.success("删除成功");
                    this.userListForRepair();
                    instance.close();
                    done();
                  })
                  .catch(() => {
                    instance.close();
                    done();
                  });
              } else {
                instance.close();
                done();
              }
            },
          });
          break;
      }
    },
    swipeClick(item) {
      this.roleId = item.id;
    },
    selectUser(item) {
      this.roleId = item.id;
    },
    reportAssign(action, done) {
      if (action === "confirm") {
        this.$api.deviceRepair
          .reportAssign({
            userId: this.roleId,
            id: this.id,
            repairTime: this.repairTime,
            remark: this.remark,
            deleted: 1,
          })
          .then((res) => {
            localStorage.setItem('updateWorkerList', new Date().getTime())
            this.$toast.success("工程师指派成功");
            done();
            this.$back()
          })
          .catch(() => {
            done();
          });
      } else {
        done();
      }
    },
    toAddRole() {
      this.$push("deviceRepair/addRole");
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.appointRole {
  height: 100%;
  padding: 0 10px;
  .sub {
    height: calc(100% - 76px);
    overflow: hidden;
    .record {
      margin-top: 14px;
      padding: 12px;
      border-radius: 8px;
      background: #ffffff;
      .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        color: $color1;
      }
      .roleList {
        .item {
          background: #f2f3f7;
          border-radius: 10px;
          margin-bottom: 10px;
          padding: 16px 24px;
          position: relative;
          display: flex;
          img {
            width: 48px;
            height: 48px;
            border-radius: 24px;
            margin-right: 12px;
          }
          .content {
            flex: 1;
            font-size: 12px;
            color: $color3;
            line-height: 23px;
            .name {
              font-size: 16px;
              margin-right: 8px;
            }
            .company {
              color: #fa8200;
              display: inline-block;
              height: 20px;
              line-height: 20px;
              background: #faeddb;
              border-radius: 4px;
              padding: 0 4px;
            }
          }
          .icon {
            line-height: 48px;
            font-size: 18px;
            color: $color3;
          }
          .check {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: #231f3a;
            border-radius: 0px 8px 0px 8px;
            position: absolute;
            top: 0;
            right: 0;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            i {
              font-size: 14px;
            }
          }
        }
        .activeItem {
          background: #3e73fb;
          .content {
            color: #ffffff;
          }
        }
        .addRole {
          background: #f2f3f7;
          border-radius: 10px;
          margin-bottom: 10px;
          height: 80px;
          text-align: center;
          color: $color3;
          font-size: 12px;
          i {
            font-size: 24px;
            margin-top: 16px;
          }
        }
        ::v-deep .van-button {
          height: 100%;
        }
      }
    }
  }
  .footer {
    margin: 18px 0;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 18px;
    color: #ffffff;
    font-size: 16px;
    color: #fff;
    background-color: #c8c9cc;
  }
  .activeFooter {
    background: #3e73fb;
  }
  .dialogContent {
    padding: 12px 22px;
    .main {
      .button {
        display: inline-block;
        padding: 0 24px;
        height: 32px;
        line-height: 32px;
        background: #f5f5f5;
        border-radius: 16px;
        font-size: 14px;
        font-weight: 400;
        color: $color1;
        border: 1px solid #f5f5f5;
      }
      .buttonActive {
        background: #d8e3ff;
        border: 1px solid #3e73fb;
        color: #3e73fb;
      }
      .timeButton {
        padding: 0;
        width: 142px;
        text-align: center;
        font-size: 12px;
      }
      .userTime {
      }
      .title {
        padding: 12px 0;
      }
      ::v-deep .el-textarea__inner {
        border: 0 none;
        background: #fbfbfb;
      }
      .datetime {
        height: 0px;
        overflow: hidden;
        transition: all 0.3s linear;
      }
      .spaceBetween {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
      }
      .timeCollapse {
        height: 42px;
        transition: all 0.3s linear;
      }
      .timePick {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
::v-deep .van-swipe-cell__right {
  right: -1px;
}
</style>
<template>
  <van-popup
    v-model="visible"
    round
    get-container="body"
    position="bottom"
    :style="{ height: 40 }"
  >
    <div class="date-time-range-popup">
      <van-datetime-picker
        v-model="selectTime"
        :show-toolbar="false"
        :type="type"
        v-bind="$attrs"
        v-on="$listeners"
        :min-date="minDate"
        :max-date="maxDate"
        @change="pickerChange"
      />
    </div>
  </van-popup>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    value: {
      // 绑定值为时间戳格式
      type: [Array, String, Object, Number],
      default: "",
    },
    // DatetimePicker 的 type
    type: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      visible: false,
      selectTime: "",
      minDate: new Date(),
      maxDate: new Date(new Date().getFullYear() + 10 + "-12-31"),
    };
  },
  created() {},
  methods: {
    show() {
      this.visible = true;
      this.selectTime = new Date();
      this.pickerChange();
    },
    pickerChange() {
      let _time = this.selectTime.getTime();
      this.$emit("changeValue", _time);
      this.$nextTick(() => {
        this.$emit("change", this.value);
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
</style>